:root {
  --primary-background: #1B1B1D;
  --secondary-background: #fff;
  --primary-text: #fff;
  --secondary-text: #1B1B1D;
}

[data-theme="light"] {
  --primary-background: #fff;
  --secondary-background: #1B1B1D;
  --primary-text: #1B1B1D;
  --secondary-text: #fff;
}

body {
    background: var(--primary-background);
    color: var(--primary-text);
}

.app {
  width: calc(100% - 20px);
  padding: 10px 10px;
  min-height: calc(100vh - 25px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: var(--primary-background);
  color: var(--primary-text);

  select {
    color: var(--primary-text);
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1rem;
    border: 0;
    background: transparent;
  }

  h1, p {
    margin: 0;
  }

  p.notice {
    text-align: center;
    font-size: 0.8rem;
  }

  a {
    color: unset;
    text-decoration: underline;
  }

  button {
    min-width: 150px;
    cursor: pointer;
    border: 0;
    margin: 0;
    padding: 10px;
    border-radius: 5px;
    background-color: var(--secondary-background);
    color: var(--secondary-text);
    position: relative;

    &.secondary {
      background-color: var(--primary-background);
      color: var(--primary-text);
      border: 1px solid var(--primary-text);
    }

    &.loading {
      color: var(--primary-text);

      svg, img {
        display: none;
      }
    }

    &.loading::after {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border: 4px solid transparent;
      border-top-color: var(--secondary-text);
      border-radius: 50%;
      animation: button-loading-spinner 1s ease infinite;
    }

    @keyframes button-loading-spinner {
      from {
        transform: rotate(0turn);
      }

      to {
        transform: rotate(1turn);
      }
    }

    img {
      position: absolute;
      top: 17px;
      left: 15px;
    }
  }

  .tabs {
    .tab-head {
      display: flex;
      flex-direction: row;

      button {
        min-width: unset;
        border: 1px solid var(--secondary-text);
        border-radius: 0;
      }
    }

    .tab-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 10px;
    }
  }

  .code {
    padding: 10px;
    background-color: black;
    color: white;
    border-radius: 5px;
    overflow-x: auto;

    code {
      overflow-wrap: normal;
      word-wrap: normal;
      white-space: pre;
    }
  }

  .login-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 80%;
    max-width: 350px;

    input {
      width: 92.5%;
      margin: 0;
      padding: 10px;
      border-radius: 5px;
      text-align: center;
    }

    button {
      width: 100%;
      //font-size: 11px;
      font-family: 'IBM Plex Sans', sans-serif;
    }
  }

  .toggle-theme {
    cursor: pointer;
    font-size: .8rem;
    position: fixed;
    top: 20px;
    right: 20px;
    min-width: unset;
  }

  .console {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 25px;

    @media screen and (max-width: 540px) {
      flex-direction: column;
    }

    .metadata {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .controls {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .popup-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);

    .popup {
      margin: 100px auto;
      max-height: calc(100vh - 250px);
      overflow-y: auto;
      padding: 20px;
      border: 0;
      background: var(--primary-background);
      border-radius: 5px;
      width: calc(90% - 40px);
      max-width: 600px;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 10px;
      color: var(--primary-text);

      @media screen and (max-width: 540px) {
        margin: 10vh auto;
      }

      h1, h2 {
        margin: 0;
      }
    }
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 160px;
  height: 160px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid var(--secondary-background);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 72px;
    left: 72px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 72px;
    left: 72px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 72px;
    left: 72px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 144px;
    height: 144px;
    opacity: 0;
  }
}
